import React from "react";
import { graphql } from "gatsby";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import {
  StepsRightArrow,
  StepsWrapper,
  StepsItem,
  StepsNumber,
  StepsHeading,
} from "../../style/Steps";

const Steps = ({ title, steps, id, variant, mt }) => (
  <Wrapper id={id} mt={mt}>
    <Container flexDirection={"column"}>
      <Heading as={"h2"} lineHeight={"38px"} mb={3} uppercase>
        {title ?? "Jak to probíhá?"}
      </Heading>
      <Flex>
        <StepsWrapper variant={variant}>
          {steps.map((step, index) => (
            <StepsItem
              key={index}
              borderRightWidth={
                index + 1 !== steps.length && [null, null, null, "1px"]
              }
              borderBottomWidth={index + 1 === steps.length && 0}
            >
              <StepsNumber variant={variant} as={"span"}>
                {index + 1}
              </StepsNumber>
              <Flex
                flexDirection={"column"}
                ml={"10px"}
                mr={"20px"}
                pt={[null, null, null, 9]}
              >
                <StepsHeading variant={variant}>{step.heading}</StepsHeading>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  color={
                    variant === "carInsurance"
                      ? "carInsurance.stepsText"
                      : "negative"
                  }
                >
                  {step.description}
                </Text>
              </Flex>
            </StepsItem>
          ))}
        </StepsWrapper>
        <StepsRightArrow variant={variant} />
      </Flex>
    </Container>
  </Wrapper>
);

export default Steps;

export const ProductSteps = graphql`
  fragment ProductSteps on wp_Product_Pagecontent {
    stepsSection {
      heading
      description
    }
  }
`;
