import React from "react";
import { graphql } from "gatsby";

import Link from "../../components/Link";
import SEO from "../../components/seo";
import Steps from "../../components/Steps";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import { Hero } from "../../components/HeroV2";
import MagazineBlock from "../../components/MagazineBlock";
import ProductsBlock from "../../components/ProductsBlock";
import ProductInfoBlock from "../../components/ProductInfoBlock";
import StoryBlockSlider from "../../components/StoryBlock/StoryBlockSlider";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Button } from "../../style/Button";

import { URLS } from "../../const/const";
import { randomizeArray } from "../../utils";

const ProductDetailTemplate = ({ data }) => {
  const {
    wp: {
      product: {
        id,
        slug,
        title,
        featuredImage,
        pageContent: {
          banner,
          maincontentbanner: mainContentBanner,
          stepsSection: steps,
          selectedStoriesGeneral: stories,
        },
        categories: { nodes: categories },
        productMedailon,
      },
    },
  } = data;
  const otherProducts =
    categories &&
    categories[0].products.nodes.filter(product => product.id !== id);

  const newestPosts = categories && categories[0].posts.nodes;

  const breadcrumbs = [
    {
      label: "S čím pomůžeme",
      link: URLS.situations,
    },
    {
      label: categories[0].name,
      link: `/${categories[0].slug}`,
    },
    { label: title, link: slug },
  ];

  return (
    <Layout variant="grey">
      <SEO title={title} description={productMedailon.description} />
      <Header variant="grey" activeNav={URLS.situations} />
      <Hero
        variant={"grey"}
        heroBg="panelSecondary"
        banner={banner}
        image={featuredImage}
        breadcrumbs={breadcrumbs}
      />

      {steps && <Steps steps={steps} />}

      {mainContentBanner.titlebanner && (
        <ProductInfoBlock banner={mainContentBanner} />
      )}

      {stories && (
        <>
          <Wrapper>
            <Container flexDirection="row" justifyContent={"space-between"}>
              <Flex
                width={[1, null, null, "70%"]}
                maxWidth={["600px", null, null, "70%"]}
                pb={[0, null, null, 0]}
              >
                <Heading
                  as="h2"
                  mt={[4, null, 52]}
                  mb={3}
                  fontSize={[4, null, 5]}
                  lineHeight={0}
                  color="headlines"
                  fontWeight="light"
                  letterSpacing="caps"
                >
                  JAK JSME POMOHLI NAŠIM KLIENTŮM
                </Heading>
              </Flex>
            </Container>
          </Wrapper>
          <StoryBlockSlider
            stories={randomizeArray(stories)}
            mb={4}
            showLeftPane
            showProducts
            variant={"grey"}
            noGradient
          />
        </>
      )}

      <Wrapper variant="grey">
        <Container>
          <MagazineBlock
            posts={newestPosts}
            categoryTitle={categories[0].name}
            categorySlug={categories[0].slug}
            mt={44}
            variant={"grey"}
          />
        </Container>
      </Wrapper>

      <Wrapper variant="light">
        <Container>
          <Flex flexDirection={"column"}>
            <Heading
              as={"h2"}
              fontSize={[24, null, 5]}
              lineHeight={["24px", null, "38px"]}
              fontWeight={300}
              color={["headlinesSecondary", null, "headlines"]}
              uppercase
              mt={[42, null, 56]}
              width={"80%"}
            >
              POMŮŽEME VÁM VYŘEŠIT TAKÉ
            </Heading>
            {otherProducts && (
              <ProductsBlock products={otherProducts} withHeading />
            )}
          </Flex>
        </Container>
        <Container justifyContent={"center"} mb={65}>
          <Button
            variant="secondary"
            as={Link}
            href={`/${categories[0].slug}`}
            alignSelf="center"
            textTransform={"uppercase"}
          >
            Zpět na {categories[0].name}
          </Button>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default ProductDetailTemplate;

export const pageQuery = graphql`
  query PRODUCT_DETAIL($slug: ID!) {
    wp {
      product(id: $slug, idType: URI) {
        id
        title
        slug
        uri
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        productMedailon {
          description
          title
          icon {
            sourceUrl
          }
        }
        pageContent {
          template
          banner {
            content
            title
            cta {
              target
              title
              url
            }
            fieldGroupName
            titletop
          }
          ...ProductInfoBanner
          ...ProductSteps
          selectedStoriesGeneral {
            ...StoryInformation
          }
        }
        categories {
          nodes {
            name
            slug
            products(first: 4) {
              nodes {
                id
                title
                slug
                uri
                pageContent {
                  template
                }
                productMedailon {
                  description
                  title
                  icon {
                    sourceUrl
                  }
                }
              }
            }
            posts(first: 4) {
              nodes {
                ...PostInformation
              }
            }
          }
        }
      }
    }
  }
`;
