import styled from "styled-components";
import { Text } from "../Text";

const ProductInfoBlockText = styled(Text)``;

ProductInfoBlockText.displayName = "ProductInfoBlockText";

ProductInfoBlockText.defaultProps = {
  maxWidth: 750,
  fontSize: [18, null, 20],
  lineHeight: ["21px", null, "23px"],
  color: "headlines",
  mb: 4,
};

export { ProductInfoBlockText };
