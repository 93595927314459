import styled from "styled-components";
import { Image } from "../Image";

const ProductInfoBlockImage = styled(Image)`
  object-fit: cover;
`;

ProductInfoBlockImage.displayName = "ProductInfoBlockImage";

ProductInfoBlockImage.defaultProps = {
  width: 1,
  maxWidth: ["none", null, null, 280],
  flex: ["1 0 100%", null, null, "1 0 28%"],
  height: "auto",
  maxHeight: [200, 250, null, "100%"],
  borderRadius: 1,
  mb: [30, null, null, 0],
};

export { ProductInfoBlockImage };
