import styled from "styled-components";
import { Flex } from "../Grid";

const StepsItem = styled(Flex)``;

StepsItem.displayName = "StepsItem";

StepsItem.defaultProps = {
  width: ["100%", null, null, "25%"],
  px: "6px",
  py: 16,
  borderWidth: 0,

  borderStyle: "solid",
  borderColor: "panelSecondary",

  borderBottom: ["1px solid", null, null, "0"],
  borderBottomColor: "panelSecondary",
};

export { StepsItem };
