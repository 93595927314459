import styled from "styled-components";
import { Box } from "../Grid";
import { variant } from "styled-system";

const StepsRightArrow = styled(Box)`
  ${variant({
    variants: {
      carInsurance: {
        borderLeftColor: "carInsurance.stepsBg",
      },
    },
  })};
`;

StepsRightArrow.displayName = "StepsRightArrow";

StepsRightArrow.defaultProps = {
  width: 0,
  height: 0,
  borderStyle: "solid",

  borderTopWidth: [null, null, "135px", "125px", "100px"],
  borderBottomWidth: [null, null, "135px", "125px", "100px"],
  borderTopColor: "transparent",
  borderBottomColor: "transparent",

  borderLeftColor: "#004C64",
  borderLeftWidth: "30px",
  borderRightWidth: 0,

  zIndex: 0,
  display: ["none", null, null, null, "block"],
};

export { StepsRightArrow };
