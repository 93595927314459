import styled from "styled-components";
import { Flex } from "../Grid";

const ProductInfoBlockWrapper = styled(Flex)``;

ProductInfoBlockWrapper.displayName = "ProductInfoBlockWrapper";

ProductInfoBlockWrapper.defaultProps = {
  flexDirection: "column",
  backgroundColor: "white.100",
  px: [0, null, null, 70],
  py: [40, null, null, 50],
  borderRadius: 6,
  width: 1,
};

export { ProductInfoBlockWrapper };
