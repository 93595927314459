import styled from "styled-components";
import { Flex } from "../Grid";
import themeGet from "@styled-system/theme-get";
import { variant } from "styled-system";

const StepsWrapper = styled(Flex)`
  background-color: ${themeGet("colors.panelDark")};
  ${variant({
    variants: {
      carInsurance: {
        backgroundColor: "carInsurance.stepsBg",
      },
    },
  })};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  filter: drop-shadow(3px 5px 15px rgba(101, 124, 136, 0.5));

  @media screen and (max-width: ${themeGet("breakpoints.xl")}) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

StepsWrapper.displayName = "StepsWrapper";

StepsWrapper.defaultProps = {
  flexDirection: "row",
  flexWrap: "wrap",
  borderRadius: 1,
  height: [null, null, null, null, 200],
};

export { StepsWrapper };
