import React from "react";
import { graphql } from "gatsby";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { ProductInfoBlockHeading, ProductInfoBlockImage, ProductInfoBlockText, ProductInfoBlockWrapper } from "../../style/ProductInfoBlock";
import { ProductInfoBlockBottomSection } from "../../style/ProductsBlock";
import { StepsNumber } from "../../style/Steps";

const ProductInfoBlock = ({ banner: { titlebanner, textundertitle, rightsection } }) => (
  <Wrapper bg={["white.100", null, null, "storyBlock.background"]} mt={[16, null, 30]}>
    <Container>
      <ProductInfoBlockWrapper>
        <ProductInfoBlockHeading as={"h2"} uppercase>
          {titlebanner}
        </ProductInfoBlockHeading>
        <ProductInfoBlockText>{textundertitle}</ProductInfoBlockText>
        <ProductInfoBlockBottomSection>
          {rightsection && (
            <Flex flexDirection={"column"} width={1}>
              <Heading as={"h3"} mb={22} fontSize={28} lineHeight={"29px"} color={"headlines"} fontWeight={300}>
                {rightsection.titleofsection}
              </Heading>
              {rightsection.itemstobeware && (
                <Flex as={"ol"} flexDirection={"column"} lineHeight={"23px"}>
                  {rightsection.itemstobeware.map((item, index) => (
                    <Flex
                      as={"li"}
                      width={1}
                      flexDirection={"row"}
                      justifyContent={["space-between", null, null, null, "space-between"]}
                      flexWrap={"wrap"}
                      mb={[16, null, 23]}
                      pt={index !== 0 && [20, null, null, 56]}
                      pb={index + 1 !== rightsection.itemstobeware.length && [30, null, null, 56]}
                      borderBottom={index + 1 !== rightsection.itemstobeware.length && "1px solid"}
                      borderColor={"forms.border"}
                      key={index}
                    >
                      <StepsNumber as={"span"} order={[2, null, null, 1]} mr={[20, null, null, 16]}>
                        {index + 1}
                      </StepsNumber>
                      {/* TODO: Make a wrapper component for images */}
                      <ProductInfoBlockImage
                        src={item?.image?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
                        order={[1, null, null, index % 2 !== 0 ? 3 : 2]}
                        ml={index % 2 !== 0 && [0, null, null, 16]}
                        mr={index % 2 === 0 && [0, null, null, 16]}
                      />
                      <Flex
                        flexDirection={"column"}
                        width={1}
                        maxWidth={["none", null, null, 555]}
                        flex={["1 0 85%", null, "1 0 90%", "1 0 55%"]}
                        order={[3, null, null, index % 2 !== 0 ? 2 : 3]}
                      >
                        <Heading
                          as={"h4"}
                          fontSize={20}
                          lineHeight={"23px"}
                          fontWeight={"normal"}
                          color={"headlines"}
                          mb={[18, null, "8px"]}
                          uppercase
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <Text fontSize={[15, null, 16]} lineHeight={["20px", null, "23px"]} dangerouslySetInnerHTML={{ __html: item.content }} />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              )}
            </Flex>
          )}
        </ProductInfoBlockBottomSection>
      </ProductInfoBlockWrapper>
    </Container>
  </Wrapper>
);

export default ProductInfoBlock;

export const ProductInfoBanner = graphql`
  fragment ProductInfoBanner on wp_Product_Pagecontent {
    maincontentbanner {
      rightsection {
        itemstobeware {
          image {
            sourceUrl(size: MEDIUM)
          }
          content
          title
        }
        titleofsection
      }
      textundertitle
      titlebanner
    }
  }
`;
