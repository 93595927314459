import styled from "styled-components";
import { Flex } from "../Grid";
import { variant } from "styled-system";

const StepsNumber = styled(Flex)`
  ${variant({
    variants: {
      carInsurance: {
        color: "white.100",
      },
    },
  })};
`;

StepsNumber.displayName = "StepsNumber";

StepsNumber.defaultProps = {
  alignItems: "flex-start",
  justifyContent: "center",
  minWidth: "38px",
  color: "cta",
  fontSize: 48,
  lineHeight: "48px",
  fontWeight: 200,
};

export { StepsNumber };
