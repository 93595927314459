import styled from "styled-components";
import { Text } from "../Text";
import { variant } from "styled-system";

const StepsHeading = styled(Text)`
  text-transform: uppercase;
  ${variant({
    variants: {
      carInsurance: {
        color: "white.100",
      },
    },
  })};
`;

StepsHeading.displayName = "StepsHeading";

StepsHeading.defaultProps = {
  fontSize: 16,
  lineHeight: "19px",
  fontWeight: 600,
  color: "cta",
  mb: "4px",
};

export { StepsHeading };
