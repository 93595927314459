import styled from "styled-components";
import { Heading } from "../Heading";

const ProductInfoBlockHeading = styled(Heading)``;

ProductInfoBlockHeading.displayName = "ProductInfoBlockHeading";

ProductInfoBlockHeading.defaultProps = {
  fontSize: [24, null, 32],
  lineHeight: ["24px", null, "38px"],
  fontWeight: 300,
  color: "headlines",
  mb: 20,
};

export { ProductInfoBlockHeading };
